export const faqList = [
    { id: 1, questions: '1.     I can’t log-on.', ans: 'Please click on ‘forgot password’ and a reminder email will be sent to your registered email address.' },
    { id: 2, questions: '2.     I haven’t received a confirmation email, have I successfully registered?', ans: 'Please check your junk mailbox.  Is the email address you registered with correct? If you have not received an email, please go to login/sign up page and re-enter your email address (user name) and password. If you have forgotten your password then please click on ‘forgot password’.' },
    { id: 3, questions: '3.	    What format can I upload my CV in?', ans: 'You can upload documents as PDF’s or Word documents' },
    { id: 4, questions: '4.     I have performed a search and no candidates have appeared – why is this?', ans: 'Clinicians with the speciality you have chosen have not registered.  Consider using a similar speciality.' },
    { id: 5, questions: '5.	    I don’t have all the information needed to complete all my profile. Does this matter?', ans: 'As long as you have registered you can be contacted. You can log in at any time to update your information and upload your CV.  We encourage you to provide as many details in all the registration screens as possible as this assists our recruiters who will then be able to understand your experience.  This will give you the best opportunity of being contacted.' },
    { id: 6, questions: '6.	    I have registered but I have not heard back yet?', ans: 'Please check your junk/spam inbox.  Hospitals will contact you directly.' },
    { id: 7, questions: '7.	    I am a Physician and I can’t find my Speciality?', ans: 'We have included all of the most common Specialities in this list, we will continue to add more as necessary.  Please log in at any time to review and update your information' },
    { id: 8, questions: '8.	    I am a Nurse and I can’t find my main Speciality?', ans: 'Please choose ‘Medical’ / ‘Surgical’, though all the main specialities are listed and you can also add further areas of expertise in the detailed registration. By providing as much information as possible will allow our recruiters to understand your experience and skills.  You can log in at any time to review and update your information as we continue to add further specialities. ' },
    { id: 9, questions: '9.	    I can’t find my Sub-Speciality?', ans: 'We have included all of the most common Sub Specialities in this list, we will continue to add more as necessary, you can log in at any time to review and update your information' },
    { id: 10, questions: '10.	I can’t find my Qualification?', ans: 'Your CV will continue to be reviewed if you complete the other fields.  We have included the most common Qualifications in this list, and we will continue to add more as necessary.  You can log in at any time to review and update your information.' },
    { id: 11, questions: '11.	What is an Allied Health Professional?', ans: 'Usually clinicians who are not trained physicians/doctors or nurse/midwives will be classed as Allied Health Professionals (AHP), such as Physiotherapists' },
    { id: 12, questions: '12.	I have a CCT or an ABMS, do I still need a local license?', ans: 'All medical professionals are required to obtain a medical license to practice' },
    { id: 13, questions: '13.	How long does it take to apply and obtain a medical license?', ans: 'Depending on your circumstances, this can take a few weeks but can take approximately 6-9 months if you do not hold an eligibility letter' },
    { id: 14, questions: '14.	What is an eligibility letter?', ans: 'This is issued once you have completed the full licensing process' },
    { id: 15, questions: '15.	I have dual nationality, can I select more than one?', ans: 'Yes, you are able to multi-select nationalities' },
    { id: 16, questions: '16.	Do I have to be able to speak Arabic?', ans: 'The working language in the UAE is English, though some positions will require knowledge of Arabic but this will be stated in the job advert' },
    { id: 17, questions: '17.	How can I see more details of the job?', ans: 'Our recruiters will review your CV and will contact you directly to discuss their opportunities ' },
    { id: 18, questions: '18.	I am visiting the region; can an interview be set up?', ans: 'Our recruiters will review your CV and contact you directly to discuss our opportunities.' },
    { id: 19, questions: '19.	What are the hours and shifts?', ans: 'This depends on the job role but full-time positions are generally 48 hours per week.  However, our recruiters can discuss this with you once a suitable position is identified.' },
    { id: 20, questions: '20.	Is accommodation provided?', ans: 'This depends on what job you have been offered.  Usually you will either be provided with accommodation or be offered an accommodation allowance.' },
    { id: 21, questions: '21.	How long are employment contracts?', ans: 'This depends but are usually permanent contracts.' },
    { id: 22, questions: '22.	Do I have to pay for your services?', ans: 'There are no recruitment fees payable.' },
    { id: 23, questions: '23.	What Browser do I need?', ans: 'You are able to access the system using a variety of Browsers, including Chrome, FireFox and Edge.' },
    { id: 24, questions: '24.	Are there any system specific requirements, such as Java, Flash etc?', ans: 'No, this is a web-based application.' },
]